import model from "./../../assets/images/Working.gif"

import styles from "./../../styles/style.module.scss"

function Human() {
  return (
    <div className={styles.Human} >
      <img src={model} alt="model" />
    </div>
  )
}

export default Human
