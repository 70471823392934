import Home from "./pages/Home";

import styles from './styles/style.module.scss'


function App() {
  return (
    <div className={styles.App} >
      <Home/>
    </div>
  );
}

export default App;
