import { useState } from "react";
import axios from "axios";

import Spinner from "./../../components/Spinner/Index";

import styles from "./../../styles/style.module.scss";

function Form() {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  function changeEmail(e) {
    setEmail(e.target.value.toLowerCase());
  }

  const validateEmail = (email) =>
    email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  async function submitEmail(e) {
    e.preventDefault();
    setSubmitted(true);

    if (!validateEmail(email)) {
      setSubmitted(false);
      return alert("Invalid email address");
    }

    const api = process.env.REACT_APP_API_URI;
    const data = { email };

    try {
      let susbscriptionRequest = await axios.post(api, data, {
        auth: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_SECRET_KEY,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (susbscriptionRequest.data.success) {
        return setSubscribed(susbscriptionRequest.data.success);
      }
      setSubmitted(false);
    } catch (error) {
      setSubmitted(false);
    }
  }

  return (
    <form className={styles.Form} onSubmit={submitEmail}>
      <h2>
      Powering Africa’s Disability Workforce
      </h2>
      <p>
        DiGNIFY is connecting inclusive employers to top talents from the disability community in Africa. We match jobseekers with disabilities to growth and employment opportunities.
      </p>
      <p>Join our waiting list to know when we launch!</p>
      {subscribed ? (
        <div className={styles.Subscribed}>
          <h3>Congratulations!</h3>
          <p>You are now on our waitlist!</p>
          <p>We will send you an update as soon as we launch.</p>
        </div>
      ) : (
        <div className={["flex", styles.FormInput].join(" ")}>
          <input
            type="email"
            value={email}
            placeholder="Your Email"
            onChange={changeEmail}
            disabled={submitted}
          />
          <button disabled={submitted}>
            {submitted ? <Spinner /> : "Join the Waitlist"}
          </button>
        </div>
      )}
    </form>
  );
}

export default Form;
