import linkedin from "./../../assets/icons/linkedin.svg";
import facebook from "./../../assets/icons/facebook-logo-2019.svg";
import twitter from "./../../assets/icons/twitter.svg";
import instagram from "./../../assets/icons/instagram.svg";

import styles from "./../../styles/style.module.scss"

function Footer() {
  return (
    <div className={styles.Footer} >
      <a href="https://www.linkedin.com/company/dignifylabs/" target="_blank" rel="noreferrer">
        <img src={linkedin} alt="Dignify-LinkedIn" />
      </a>
      <a href="https://www.facebook.com/dignifylabs" target="_blank" rel="noreferrer">
        <img src={facebook} alt="Dignify-Facebook" />
      </a>
      <a href="https://www.twitter.com/@dignifylabs" target="_blank" rel="noreferrer">
        <img src={twitter} alt="Dignify-Twitter" />
      </a>
      <a href="https://www.instagram.com/dignifylabs" target="_blank" rel="noreferrer">
        <img src={instagram} alt="Dignify-Instagram" />
      </a>
    </div>
  );
}

export default Footer;
