import React from "react";
import Form from "../components/Form/Index";
import Navbar from "../components/Navbar/Index";
import Human from "../components/Human/Index";
import Footer from "../components/Footer/Index";

function Home() {
  return (
    <div>
      <Navbar />
      <div className="flex">
        <Form />
        <Human />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
