import styles from "./../../styles/style.module.scss";

function Navbar() {
  return (
    <nav className={styles.Nav} >
      Dignify
    </nav>
  )
}

export default Navbar
